import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import 'vant/lib/index.css';

import i18n from './lang/index.js';

import Meta from 'vue-meta';
Vue.use(Meta);
import tabbar from './components/tabbar/tabbar.vue';
Vue.component('tabbar', tabbar);

import invite from './components/Invite.vue';
Vue.component('invite', invite);

import globalMixin from './untils/globalMixin';
Vue.mixin(globalMixin);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueCountryIntl from 'vue-country-intl';
// 引入css
import 'vue-country-intl/lib/vue-country-intl.css'
import '@/assets/css/tailwind.css'
// 全局注册组件
Vue.component(VueCountryIntl.name, VueCountryIntl);

Vue.config.productionTip = false
import { Loading, Swipe, SwipeItem, Icon, Popup, Tab, Tabs, List, Field,Checkbox,Uploader,Toast,Dialog,CellGroup,Cell,Picker,Radio,RadioGroup,Overlay,PasswordInput,NumberKeyboard,ActionSheet } from 'vant';

Vue.use(Loading).use(Swipe).use(SwipeItem).use(Icon).use(Popup).use(Tab).use(Tabs).use(List).use(Field).use(Picker).use(Radio).use(PasswordInput).use(NumberKeyboard)
.use(RadioGroup).use(Checkbox).use(Uploader).use(Toast).use(Dialog).use(CellGroup).use(Overlay).use(Cell).use(ActionSheet);

Vue.use(ElementUI)

Vue.filter('prefix', function(url) {
  if (url && url.startsWith('http')) {
    return url;
  } else {
    url = `https://img.gujilunpanguanglihoutaiyinni.life${url}`;
    return url;
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,

}).$mount('#app')
