<template>
  <div id="app">
    <img
      ref="button"
      class="custome-home"
      src="./assets/icon/customer-icon.png"
      alt=""
      @touchstart="startDrag"
      @touchmove="drag"
      @touchend="endDrag"
      :style="{ transform: `translate(${position.x}px, ${position.y}px)` }"
    />
    <router-view></router-view>
    <div class="header-box">
      <van-icon name="arrow-left" size="20" @click="$router.go(-1)"/>
    </div>
    <van-overlay :show="loading" class="z-100">
      <div class="w-full h-full flex items-center justift-center" @click.stop>
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      userinfo: null,
      isDragging: false,
      startPosition: { x: 0, y: 0 },
      position: { x: window.innerWidth - 50, y: window.innerHeight / 2 },
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      buttonWidth: 32,
      buttonHeight: 32,
      isMoved: false
    };
  },
  computed: {
    ...mapState(["customeUrl", "loading"]),
  },
  mounted() {
    this.fetchCustomeUrl();
    let token = localStorage.getItem("token");
    if (token) {
      this.fetchUserInfo()
      this.fetchBalancePlusData()
    } else {
      setTimeout(() => {
        if(this.$route.path == '/login') return;
        this.$router.push('/login').catch(err => { console.log(err) })
      }, 100);
      
    }
  },
  methods: {
    ...mapActions([
      "fetchBalancePlusData",
      "fetchUserInfo",
      "fetchCustomeUrl",
    ]),
    jumpUrl() {
      window.open(this.customeUrl);
    },
    startDrag(event) {
      event.preventDefault();
      this.isDragging = true;
      this.startPosition = {
        x: event.touches[0].clientX - this.position.x,
        y: event.touches[0].clientY - this.position.y,
      };
    },
    drag(event) {
      this.isMoved = true
      if (this.isDragging) {
        let x = event.touches[0].clientX - this.startPosition.x;
        let y = event.touches[0].clientY - this.startPosition.y;
        let buttonRect = this.$refs.button.getBoundingClientRect();

        if (x >= 0 && x + buttonRect.width <= this.screenWidth) {
          this.position.x = x;
        }
        if (y >= 0 && y + buttonRect.height <= this.screenHeight) {
          this.position.y = y;
        }
      }
    },
    endDrag() {
      this.isDragging = false;
      if(!this.isMoved) this.jumpUrl();
      this.isMoved = false;
    },
  },
};
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  padding-top: 46px;
}
.custome-home {
  position: fixed;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  cursor: grab;
  transition: transform 0.1s ease;
  z-index: 100;
}
.header-box {
  position: fixed;
  top: 0;
  width: 100%;
  height: 46px;
  line-height: 46px;
  padding-left: 10px;
  text-align: left;
  background-color: #f7f7f7;
}

</style>
